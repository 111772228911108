.favorites {
    width: calc(82% - var(--filter_width));
    overflow-y: scroll;
    height: 90vh;
    padding: 28px;
    background-color: var(--background-color);
    margin-left: calc(18% + var(--filter_width));
}

.favorites_own {
    width: calc(100% - 100px) !important;
    height: 100vh !important;
    padding-top: 100px !important;
    margin-left: 100px !important;
}

.favorites_own::-webkit-scrollbar {
    display: none;
}

.heart {
    position: absolute;
    bottom: 3%;
    right: 4%;
    cursor: pointer;
}

.favorite_empty_container {
    margin-left: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 85%;
    padding-bottom: 30px;
}

.favorite_empty_image {
    width: auto;
    height: 40%;
    margin: 20px;
}

.favorite_empty_font {
    max-width: 380px;
    padding: 20px;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {

    .favorite_empty_container {
        margin-left: 300px;
    }

    .favorite_empty_image {
        max-width: 40%;
        min-width: 500px;
    }

}

@media screen and (max-width: 680px) {

    .favorites {
        margin: 0;
        width: 100%;
    }

    .favorite_empty_image {
        max-width: 80%;
        min-width: 300px;
        height: 40%;
    }

    .favorite_empty_container {
        margin-left: 0%;
        height: 70%;
    }

    .favorites_own {
        width: 100% !important;
        margin-left: 0px !important;
    }
}