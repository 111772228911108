.profile_menu {
  position: fixed;
  width: 18%;
  padding: 20px 28px;
  border-right: 1px var(--border_color) solid;
  height: 91%;
  background-color: var(--background-color);
  left: var(--filter_width);
  text-align: left;
  min-width: 142px;
}

.menu_button {
  background: #eb4949;
  padding: 5px 12px 9px;
  gap: 10px;
  border-radius: 64px;
  width: 112px;
  height: 36px;
  cursor: pointer;
}

.menu_button1 {
  background: transparent;
  padding: 5px 7px 9px;
  gap: 10px;
  border-radius: 64px;
  margin: 20px 0;
  height: 36px;
  cursor: pointer;
  border: none;
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.menu_button1:hover {
  background: #eb4949;
}

.active {
  margin: 25px 0;
}

.menu_button1.active {
  background: #eb4949;
}

/* .menu_button1:after {
    background: #EB4949;
} */

.editer {
  position: absolute;
  bottom: 30%;
  right: 3%;
  width: 30px;
}

.profile_body {
  position: absolute;
  top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
  overflow: hidden;
  background-color: var(--background-color);
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0px;
}

.profile_body .ant-card-body {
  padding: 0;
  color: var(--font_color);
}

.profile_body .ant-card {
  background: none;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  color: var(--font_color);
}

.profile_menu a {
  padding: 10px 10px !important;
}

a:hover {
  color: var(--font_color) !important;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
  .profile_menu {
    padding: 20px 15px;
  }

  .profile_body {
    overflow: auto;
  }
}

@media screen and (max-width: 680px) {
  .profile_body {
    overflow: auto;
  }

  .editer {
    position: absolute;
    bottom: 10px;
    right: 0px;
    width: 20px;
  }

  .mobile_profile_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    //padding-left: 25px;
    border-bottom: 1px var(--border_color) solid;

    padding: "0 10px 0 0";
  }

  .swiper-wrapper {
    align-items: center;
    gap: 1px;
  }

  .menu_button1 {
    font-size: 12px;
  }

  .menu_button1.active {
    background: #eb4949;
  }

  .active {
    background: #eb4949;
    margin: 25px 0;
  }
}
