.profilecard {
  border: 1px var(--border_color) solid;
  border-radius: 20px;

  height: fit-content;
}

.main {
  padding: 28px;
  width: 100%;
  height: 91%;
  /* display: flex; */
  gap: 5%;
  margin-left: var(--filter_width);
  background: var(--background-color);
}

.gradient {
  background: linear-gradient(
    90deg,
    #4b355a 2.57%,
    #3784ab 25.48%,
    #40c397 51.66%,
    #f0e681 74.93%,
    #eb4949 99.48%
  );
  height: 82px;
  border-radius: 20px;
}

.profileavatar {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  width: 12%;
  top: 50%;
  justify-content: center;
  left: 1%;
}

.profile_person {
  display: flex;
  width: 88%;
  margin-left: 12%;
}

.profile_description {
  padding: 12px 24px;
  width: 55%;
}

.profile_connect {
  display: flex;
  align-items: center;
  height: 28px;
  background: transparent;
  border: 1px solid var(--font_color);
  border-radius: 100px;
  color: var(--font_color);
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  width: min-content;
  padding: 0 5px;
}

.profile_arrow {
  width: 28px;
  height: 28px;
  background: transparent;
  /* border: 1px solid #FFFFFF;
    border-radius: 36px; */
  color: var(--font_color);
}

.profile_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.profile_buttons {
  display: flex;
  gap: 10px;
}

.person_content {
  text-align: left;
  padding-top: 12px;
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--font_color6);
}

.profile_state {
  text-align: left;
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  width: 45%;
}

.state_value {
  padding: 12px 24px;
  display: flex;
  gap: 20px;
}

.font20 {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.font12 {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  display: flex;
}

.font12_mono {
  font-family: "Vulf Mono Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;

  /* Greyscale/400 */

  color: #adadad;
}

.font12_2 {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--font_color2);
}

.font12_6 {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--font_color6);
}

.font14 {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}

.font14_w4 {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  /* color: var(--font_color2); */
}

.font14_8 {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--font_color6);
}

.font10 {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: var(--font_color6);
}

.font10_2 {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: var(--font_color2);
}

.font16 {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.font16_6 {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--font_color6);
}

.font24 {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.font18 {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.avatar_group {
  display: flex;
  border: 1px var(--border_color) solid;
  border-radius: 20px;
  height: fit-content;
  gap: 20px;
  margin: 16px 0;
  padding: 12px 0;
  min-height: 100px;
}
.loader-creators {
  display: flex;
  align-items: center;
}
.avatar_group img {
  width: 72px;
  height: 72px;
}

.mySwiper {
  padding: 12px 15px;
  z-index: 0 !important;
}

.card_group {
  height: 100px;
}

/* .scroll {
    max-height: 250px;
    overflow-y: scroll; */
/* overflow-y: hidden; */
/* } */

.scroll::-webkit-scrollbar {
  display: none;
}

.cardbtn {
  background: transparent;
  border-radius: 5px;
  color: var(--font_color);
  border: 1px solid;
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 8px;
  gap: 5px;
  cursor: pointer;
}

.pinbtn {
  color: var(--font_color);
  border-radius: 5px;
  border: none;
  margin: 0 5px;
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 8px;
  gap: 5px;
  cursor: pointer;
}

.card_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
}

.location {
  width: 100%;
  border: 1px solid var(--font_color2);
  border-radius: 20px;
  padding: 16px 20px;
  text-align: left;
}

#button-tooltip {
  width: 300px;
  background-color: var(--background-color);
  border: 1px solid var(--font_color2);
  border-radius: 5px;
  color: var(--font_color);
  padding: 18px 16px;
  margin-top: 10px;
}
#button-tooltip1 {
  width: 150px;
  background-color: var(--background-color);
  border: 1px solid var(--font_color2);
  border-radius: 5px;
  color: var(--font_color);
  padding: 10px 10px;
  margin-top: 10px;
}

.tooltip-inner {
  max-width: 100%;
  background-color: var(--background-color);
  color: var(--font_color);
}

.tooltip-share-link {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 190px;
  display: block;
  overflow: hidden;
}

.main .ant-card-body {
  padding: 0;
  color: var(--font_color);
}

.main .ant-card {
  background: none;
}

.ant-modal-content {
  background-color: var(--background-color) !important;
  color: var(--font_color);
  border-radius: 12px !important;
}
s .ant-modal-title {
  color: var(--font_color) !important;
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
}

.phonenumber {
  background: transparent;
  border: none;
}

.dashborder {
  margin-top: 20px;
  padding: 11px 20px 12px;
  border: 2px dashed var(--font_color);
  border-radius: 56px;
  width: fit-content;
  margin: auto;
}

.ant-modal-footer {
  background: #565656 !important;
  border-radius: 0 0 12px 12px !important;
  border-top: 0px !important;
}

.main_second_part {
  right: 2%;
  width: 20%;
  position: fixed;
}

.incorrect-phone-input input {
  border-bottom: 2px solid #eb4949 !important;
}

.correct-phone-input input {
  border-bottom: 2px var(--font_color6) solid !important;
}

.ant-input-affix-wrapper > input.ant-input {
  background-color: var(--background-color);
  color: var(--font_color);
}

.ant-input-affix-wrapper {
  box-shadow: none;
  border: none !important;
}

.state_half {
  display: flex;
  gap: 20px;
}

.profile_date {
  display: flex;
  gap: 5px;
}

.state_first_part {
  min-height: 50%;
  border-bottom: 1px var(--border_color) solid;
  display: grid;
  align-items: center;
}

.state_second_part {
  display: grid;
  align-items: center;
}

.profile_item {
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 10px 0;
}
.avatar_item {
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  margin: auto;
  width: fit-content;
}
.avatar_item.active1 {
  border: 1px red solid;
}
.active1 {
  border: 1px red solid;
}
.desktop {
  display: none !important;
}

.mobile_profile_avatar {
  width: 112px;
  height: 112px;
  clip-path: circle();
}

.mobile_profile_from {
  color: var(--font_color6);
}

.mobile_bio {
  padding: 50px 0 0px 0;
  flex-direction: column;
}
.mobile_bio_header {
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile_bio_name {
  font-family: "Recoleta";
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: var(--font_color);
}

.mobile_buttons {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.mobile_connect {
  background-color: #f8f0e3;
  color: #0d0d0d;
  margin-right: 8px;
  padding: 0 10px;
}

.mobile_bio_description {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--font_color6);
  margin: 8px 0 20px 0;
  text-align: start;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
  .state_value {
    display: block;
    padding: 0 24px;
  }

  .profile_date {
    display: block;
  }

  .person_content {
    width: 100%;
  }

  .main {
    padding: 10px;
  }

  .location {
    padding: 7px 10px;
  }
}

@media screen and (max-width: 780px) {
  .profile_description {
    padding: 12px 4px;
  }
  .mobile_profile_avatar {
    width: 94px;
    height: 94px;
  }
}

@media screen and (max-width: 680px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex !important;
  }
  .type_value {
    font-size: 16px;
  }

  .profilecard {
    margin-top: 20px;
  }
  .profile_description {
    padding: 12px 24px;
  }

  .profile_buttons {
    display: grid;
    gap: 2px;
    justify-content: end;
  }

  .desktop_version {
    display: none;
  }
  .state_first_part {
    border-bottom: none;
  }
  .main {
    display: block;
    margin-left: 0px;
    padding: 28px 14px;
  }

  .main_first_part {
    width: 100%;
  }

  .profile_person {
    display: block;
    width: 100%;
    margin-left: 0%;
  }

  .profile_description {
    width: 100%;
    border: none;
  }

  .profileavatar {
    left: 4%;
    width: 30%;
  }

  .profile_header {
    padding-left: 34%;
    justify-content: space-between;
  }

  .person_content {
    padding-top: 15px;
    width: 100%;
  }

  .profile_state {
    border-top: 1px var(--border_color) solid;
    width: 100%;
    display: flex;
  }

  .state_first_part {
    width: 50%;
    border-right: 1px var(--border_color) solid;
  }

  .state_second_part {
    width: 50%;
    padding-top: 10%;
    display: block;
  }

  .state_value {
    border: none;
    display: block;
  }

  .main_second_part {
    margin-top: 20px;
    width: 100%;
    position: static;
  }
}

@media screen and (max-width: 370px) {
  .mobile_profile_from {
    margin-left: 12px;
  }
}
