body {
  background-color: #0d0d0d;
}

.legal-content {
  text-align: left;
  color: var(--font_color);
  background-color: var(--background-color);
  padding: 80px 30px;
  max-width: 1000px;
  margin: auto;
}

.legal-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 40px 0px 50px 0px;
}

.legal-nav-link span,
.legal-nav-link a {
  text-transform: capitalize;
  text-align: center;
  display: block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  text-align: center;
  background-color: #ffffff;
  color: #000000 !important;
}

.legal-nav-link span {
  cursor: default;
  background-color: #eb4949;
  color: #ffffff !important;
}

@media (max-width: 400px) {
  .legal-nav {
    gap: 12px;
  }

  .legal-nav-link span,
  .legal-nav-link a {
    font-size: 10px;
    width: 70px;
  }

}