.payment_info {
    display: block;
    width: 25%;
    padding: 48px;
    border-right: 1px var(--border_color) solid;
    height: calc(100vh - 9%);
    background-color: var(--background-color);
    margin-left: calc(18% + var(--filter_width));
    text-align: left;

}

.payment_select {
    text-align: center;
    display: flex;
    border-radius: 5px;
    border: 1px solid #565656;
    overflow: hidden;
    margin: 15px 0;
}

.payment_select .dots-loader {
    width: auto !important;
    flex: 1;
}

.payment_select_button {
    font-family: "Vulf Sans Demo";
    flex: 1;
    border: 0;
    margin: 0;
    background-color: transparent;
    color: var(--font_color2);
    padding: 8px 20px;
    font-size: 14px;
}

.payment_select_button:focus {
    outline:0;
}

.payment_select_button.selected {
    background-color: #565656;
    color: #ffffff;
}

.day_year {
    flex: 1 0 auto;
}

.payment_buttons {
    position: absolute;
    bottom: 0%;
    padding: 30px;
    background-color: var(--background-color);
    gap: 20px;
    display: flex;
    margin-left: calc(var(--filter_width) + 18%);
}

.ant-radio-group-large .ant-radio-button-wrapper {
    width: 50%;
}

.ant-radio-group {
    display: flex !important;
}

.payment_cvc .ant-input {
    border-bottom: none !important;
    height: 70%;
}

.payment_cvc {
    border-bottom: 1px solid var(--font_color2);
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
    .payment_info {
        border: none;
        width: 50%;
    }

    .payment_buttons {
        width: calc(82% - var(--filter_width));
        justify-content: space-between;
    }
}

@media screen and (max-width: 680px) {
    .payment_info {
        margin: 0;
        width: 100%;
    }

    .payment_buttons {
        margin: 0;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: var(--background-color);
    }
}