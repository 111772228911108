.video .modal-body {
    background: #fff;
    color: #000;
}

.video {
    width: 100%
}

.player {
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    font-size: 0;
    overflow: hidden;
}

/* This css is only applied when fullscreen is active. */
.player:fullscreen {
    max-width: none;
    width: 100%;
}

.player:-webkit-full-screen {
    max-width: none;
    width: 100%;
}

.pre_images {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 15px 18px 22px;
    overflow: scroll;
    height: 94%;
    width: 20%;
    position: absolute;
    right: 0%;
    top: 0%;
}

.pre_image {
    margin: 10px;
    width: 130px;
    border-radius:7px;
}
.pre_images::-webkit-scrollbar{
    display:none;
}

.player__video {
    /* width: 100%; */
    border-radius: 12px;
    height: 500px;
    margin: auto;
}

.image_preview {
    width: 40%;
}

.player__slider {
    width: 10px;
    height: 30px;
}

.player__controls {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%) translateY(-5px);
    transition: all .3s;
    flex-wrap: wrap;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.31) 51.56%, rgba(0, 0, 0, 0.7) 100%);
    padding: 10px;
    justify-content: space-between;
}
.video_pause{
    position: absolute;
    left: 50%;
}
.player:hover .player__controls {
    transform: translateY(0);
}

.player:hover .progress {
    height: 2px;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.progress {
    flex: 10;
    position: relative;
    display: flex;
    flex-basis: 100%;
    height: 2px;
    transition: height 0.3s;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.progress__filled {
    width: 50%;
    background: #ffc600;
    flex: 0;
    flex-basis: 0%;
}
.video_close{
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;
    cursor: pointer;
}
.image-preview-main-picture {
    background: var(--background-color);
    border-radius: 12px;
    justify-content: center;
    min-height: 400px;
    overflow: hidden;
    padding: 20px;
}
@media screen and (max-width: 680px) {
    .pre_images{
        display: none;
    }
    .pre_image{
        width: 65px;
    }
    .mobile_pre_images{
        border-radius: 12px;
        background: var(--background-color);
        overflow-x: scroll;
    }
    .image_preview {
        width: 100%;
        border-radius: 12px;
    }
}