.fullscreen {
    width: 100%
}

.fullcard_image {
    width: 100%;
    border: 1px solid rgba(13, 13, 13, 0.2);
    border-radius: 20px;
}

.full_pre_image {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 15px 18px 22px;
    overflow: scroll;
    height: 95%;
    width: 12%;
    position: absolute;
    top: 0%;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pre_image {
    width: 90%;
}

.full_pre_image::-webkit-scrollbar {
    display: none;
}
.full_buttons{
    position: absolute;
    left: 2%;
    top: 3%;
}

@media screen and (min-width: 681px) and (max-width: 1200px){
    .full_pre_image{
        width: 22%;
    }
}

@media screen and (max-width: 680px) {
    .full_pre_image {
        flex-direction: row;
    }
    .pre_image {
        width: 65px;
    }
    .full_pre_image{
        position:static;
        width: auto;
    }
    .pre_image{
        aspect-ratio: 1/1;
    }
}