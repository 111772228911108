.css-15hc2iy {
    padding: 0 !important;
}

.channels {
    bottom: 0px;
}

.homepage_cardgroup {
    margin-left: 100px;
    background-color: var(--background-color);
    padding: 28px;
    width: 100%;
    height: 91%;
}

.home_body {
    display: flex;
    position: absolute;
    top: 9%;
}
.home_main{
    padding: 28px;
    width:calc(100% - var(--filter_width));
    min-height: 91%;
    display: flex;
    gap: 5%;
    margin-left: var(--filter_width);
    background-color: var(--background-color);
}
.home_avatar_group {
    display: flex;
    height: fit-content;
    gap: 40px;
    margin: 0 0 16px 0;
    padding: 0 0 12px 0;
}
.each_avatar{
    padding: 11px 10px;
    border-radius: 20px;
    border: 1px var(--border_color) solid;
    width: 115px;
}
.ant-drawer-content {
    color: var(--font_color);
    background-color: transparent !important;
}
.white_btn{
    background-color: var(--background-color) !important;
    border: 1px #fff solid !important;
    color: var(--font_color) !important;
    margin-left: 5px;
}
.video_image{
    margin: 10px;
    border: 1px var(--border_color) solid;
    border-radius: 7px;
    overflow: hidden;
    width: 100%;
    height: 280px;
    background-position: center;
    background-size: cover;
    cursor: pointer;
}
.video_play{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
}
.avatar-creators {
    width: 83px;
    height: 83px;
    clip-path: circle(at 50% 50%);
    background-position: center center;
    background-size: cover;
}
@media screen and (max-width: 680px) {
    .home_main{
        margin:0;
        width: 100%;
        padding: 0px 20px;
    }
    .each_avatar{
        padding: 11px 10px;
    }
}