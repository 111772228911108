.author_header .display-header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px;
  height: 80px;
  border-bottom: 1px var(--border_color) solid;
  z-index: 2;
  width: 100%;
  background-color: var(--background-color);
}

.unscrolled_header {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.scrolled_header {
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}

.profile {
  display: flex;
  gap: 15px;
  height: 100%;
}

.profile_set {
  display: flex;
  align-items: center;
  gap: 30px;
}

.help {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--font_color6);
}

/* img {
    height: fit-content;
} */

.mobile_version {
  display: none;
}
.login_modal .ant-modal-title {
  padding: 4px;
}
.login_modal .ant-modal-header {
  padding: 12px 24px;
}
.login_modal .ant-modal-body {
  padding-left: 0;
  padding-right: 0;
}

.close_login {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
}

.checkout_close_mobile {
  display: none;
}

.checkout_close_desktop {
  display: block;
}

.ant-modal-close-x {
  display: none !important;
}

#login .ant-select-arrow {
  display: none;
}

@media screen and (max-width: 680px) {
  .checkout_close_mobile {
    display: block;
  }

  .checkout_close_desktop {
    display: none;
  }
  .help {
    display: none;
  }

  .logo {
    padding-right: 10px;
    border-right: 1px var(--border_color) solid;
  }

  .mobile_version {
    display: flex;
    justify-content: space-between;
  }

  .mobile_menu {
    color: var(--font_color);
    align-items: center;
    display: flex;
    padding-top: 5px;
  }

  .ant-modal-content {
    margin: 20px;
  }
}
