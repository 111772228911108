.connect {
    border-radius: 12px;
    margin: 50px 0 20px 0;
    padding: 50px 0 10px 0;
    cursor: pointer;
}

.connect_image {
    position: relative;
}

.connectimage {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.connect_container_pb {
    padding-bottom: 100px;
}

.link_empty_container {
    margin-left: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 85%;
    padding-bottom: 30px;
}

.link_empty_image {
    width: 270px;
    height: fit-content;
    margin: 20px;
}

.link_empty_font {
    max-width: 380px;
    padding: 20px;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {

    .link_empty_container {
        margin-left: 300px;
    }

    .link_empty_image {
        max-width: 40%;
        min-width: 270px;
        height: fit-content;
    }

}

@media screen and (max-width: 680px) {

    .link_empty_image {
        max-width: 80%;
        min-width: 270px;
        height: fit-content;
    }

    .link_empty_container {
        margin-left: 0%;
        height: 70%;
    }
}