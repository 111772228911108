.custom-styles {
  --ReactInputVerificationCode-itemWidth: 1rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
  width: fit-content;
  margin: auto;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: var(--font_color);
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: var(--border_color);
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #046cde;
}

.ant-input-affix-wrapper {
  background-color: var(--background-color) !important;
}
.ant-select-arrow {
  display: none;
}
.ant-select-selection-item {
  color: white;
}

@media screen and (max-width: 680px) {
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: 1rem;
    --ReactInputVerificationCode-itemHeight: 2.5rem;
  }
}
