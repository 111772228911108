body::-webkit-scrollbar {
  display: none;
}

.switch_mode {
  text-align: center;
  color: var(--font_color);
}

.author_body {
  display: flex;
  padding-top: 82px;
}

.avatar_author:hover {
  border-radius: 100%;
  border: 2px #eb4949 solid;
  transition: all 0.2s ease-in-out;
}

.avatar_author_active_tab {
  border-radius: 100%;
  border: 2px #eb4949 solid;
  transition: all 0.2s ease-in-out;
}

.empty_drops_text {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--font_color);
}

.order_icon_bag_container {
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 0 50% 50% 50%;
  transform: rotate(45deg);
  position: relative;
  overflow: hidden;
}

.order_icon_bag {
  /* width: 100%;
  height: 100%; */
  transform: rotate(-45deg);
  width: 132%;
  height: 162%;
  position: absolute;
  top: -22px;
  left: -12px;
}

.empty_drops {
  border: 1px var(--border_color) solid;
  border-radius: 20px;
  width: 100%;
  height: 30vh;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage_body {
  display: flex;
  position: absolute;
  background-color: var(--background-color);
  padding-top: 70px;
  width: 100%;
  min-height: calc(100vh - 9%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--font_color);
  -webkit-box-shadow: 0 0 0px 1000px var(--background-color) inset;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-input {
  border: none !important;
  border-bottom: 1px var(--font_color2) solid !important;
  background-color: transparent !important;
  color: var(--font_color);
  box-shadow: none;
}

.bordered-input:focus {
  border-bottom: 2px solid rgb(24 175 126) !important;
  box-shadow: none;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  width: 100%;
  margin: 0;
}

.space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile_version {
  display: none;
}

.desktop_version {
  display: block;
}

.cookies {
  z-index: 2;
  gap: 10px;
  background: var(--cookies_back);
  border: 1px solid rgba(163, 88, 88, 0.2);
  /* backdrop-filter: blur(14px); */
  border-radius: 12px;
  position: fixed;
  bottom: 40px;
  transform: translate(-50%, -50%);
  left: 50%;
  height: 60px;
  width: 58%;
  padding: 13px;
  justify-content: center;
  align-items: center;
}

.cookies_btn {
  /* backdrop-filter: blur(14px); */
  /* background: #0D0D0D; */
  border-radius: 36px;
  /* border: 1px solid linear-gradient(90deg, #4B355A 2.57%, #3784AB 25.48%, #40C397 51.66%, #F0E681 74.93%, #EB4949 99.48%); */
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  border-radius: 100rem;
  /* padding: 1rem; */
  padding: 5px 10px 6px;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 1px transparent;
  background-image: linear-gradient(#4b355a, #4b355a),
    linear-gradient(101deg, #4b355a, #3784ab, #40c397, #f0e681, #eb4949);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 1px 1000px 1px var(--background-color) inset;
  cursor: pointer;
}

.cookies_btn1 {
  /* backdrop-filter: blur(14px); */
  /* background: #0D0D0D; */
  border-radius: 36px;
  /* border: 1px solid linear-gradient(90deg, #4B355A 2.57%, #3784AB 25.48%, #40C397 51.66%, #F0E681 74.93%, #EB4949 99.48%); */
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  border-radius: 100rem;
  /* padding: 1rem; */
  padding: 5px 10px 6px;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 1px var(--font_color);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 1px 1000px 1px var(--background-color) inset;
  cursor: pointer;
}

.cookies_text {
  display: flex;
  gap: 5px;
}

.cookies_buttons {
  display: flex;
  gap: 5px;
}

.logined_badge {
  /* display: flex; */
  border-radius: 100px;
  border: 1px solid red;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.badge {
  position: absolute;
  left: 65%;
  bottom: 60%;
  font-size: 14px;
  background: rgb(248 240 227);
  color: #0d0d0d;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  font-family: "Recoleta";
}

.badge2 {
  position: absolute;
  left: 65%;
  bottom: 60%;
  font-size: 14px;
  background: var(--background-color);
  width: 24px;
  height: 22px;
  border-radius: 10px;
}

.login_avatar {
  align-items: center;
  justify-content: center;
  width: 55px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
}

#root {
  background: var(--background-color);
}

img {
  height: auto;
}

.mobile_spotify {
  display: none;
}

.category_badge {
  color: var(--font_color);
}

.type_badge {
  color: #fff;
}

.video_background {
  width: 99%;
  margin: 0 auto;
  height: 204px;
  border-radius: 10px 10px 0 0;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
  .cookies {
    font-size: 12px;
    width: 80%;
    right: 45%;
  }
}

@media screen and (max-width: 680px) {
  /* .order_icon_bag {
    max-width: 40px;
    min-width: 40px;
    height: 40px; 
    border-radius: 100%;
  } */
  .video_background {
    margin-top: 0;
  }

  .featured_label {
    margin-top: 36px;
  }

  .order_icon_bag_container {
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-right: 10px;
  }

  .logined_badge {
    width: 45px;
    height: 45px;
  }

  .mobile_version {
    display: block;
  }

  .desktop_version {
    display: none;
  }

  .author_body {
    display: flex;
    position: static;
    padding-top: 48px;
  }

  .cookies {
    width: 94%;
    padding: 10px;
    height: auto;
    bottom: -5%;
  }

  .cookies_buttons {
    display: block;
    text-align: end;
  }

  .cookies_text {
    display: block;
    text-align: left;
  }

  .cookies_btn1 {
    margin-bottom: 5px;
  }

  .cookies_btn {
    width: max-content;
  }

  .mobile_spotify {
    display: none;
    position: fixed;
    /* bottom: 5%; */
  }
}

.commonError {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  font-size: 21px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  background-color: inherit;
  padding: 10px 14px;
  font-size: 1em;
  border-bottom: 1px solid var(--font_color2);
  background-color: var(--background-color) !important;
  outline: 0;
  width: 100%;
  min-width: 50px;
}

.StripeElement--webkit-autofill {
  background-color: var(--background-color);
}

.StripeElement--focus {
  border-bottom: 2px solid #01896a;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.close_button_pointer {
  z-index: 3;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.close_button {
  z-index: 3;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.close_button_pointer:hover,
.close_button:hover {
  opacity: 0.8;
  transform: scale(1.2);
  transform: rotate(20deg);
}

.close_button_mini {
  z-index: 3;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
