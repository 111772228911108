
.floating-nav {
  z-index: 1;
  position: fixed;
  width: 285PX;
  height: 50px;
  gap: 40px;
  border: 1px solid #CCB783;
  border-radius: 38px;
  background-color:  var(--background-color);
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-inline: 15px;
}

.floating-nav__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
}

.floating-nav__icon a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-nav__img {
  height: 23px;
}

.floating-nav__profile-img {
  border-radius: 20px;
  height: 24px;
  width: 24px;
  border: 1px solid #CCB783;
}

.floating-nav__profile-active {
  background-position: center;
  height: 100%;
}

.connect-button {
  line-height: 14px;
  background: transparent;
  border: 1px solid var(--font_color);
  border-radius: 100px;
  color: var(--font_color);
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: min-content;
  padding: 6px 10px;
  white-space: nowrap;
  cursor: pointer;
}

.connect-button:focus {
  outline: none;
}

.connect-button--connected {
  color: #ffffff;
  background-color: #eb4949;
  border: 0px solid var(--font_color);
}