.globemap {
    margin-left: var(--filter_width);
    width: calc(82% - var(--filter_width));
    padding: 0 -20px;
}

/*  fontFamily: 'Vulf Sans Demo', fontWeight: 400, fontSize: '14px', color: 'var(--font_color)'*/
.map_button {
    border-radius: 4px 0 0 4px;
    width: 120px;
    height: 30px;
    border: solid var(--button_color) 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.map_button_active {
    cursor: pointer;
    border-radius: 4px 0 0 4px;
    width: 120px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--button_color);
}

@media screen and (max-width: 680px) {
    .map_container {
        padding: 24px 0;
    }

    .globe_map_container {
        position: fixed;
        width: 100vw;
        left: 0;
    }

    .rsm-svg {
        position: absolute;
        left: calc(50% - 250px);
    }
}