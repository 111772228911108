.performance {
  position: absolute;
  top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
  overflow: hidden;
  background: #0d0d0d;
  color: #fff;
}
.tab_button {
  background: #ffffff99;
  border-radius: 8px;
  width: 100%;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.isActiveButton {
  background: white;
}

.tab_button_container {
  display: flex;
  width: 100%;
}

.text_container {
  margin-top: 25px;
  display: flex;
  width: 100%;
  border: 1px solid red;
}

.tab_container {
  width: 100%;
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  gap: 10px;
}

.empty_container {
  margin-top: 30px;
  color: antiquewhite !important;
}

.button_text {
  font-family: "Vulf Sans Demo";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: rgba(3, 3, 3, 1);
}

.chart_container {
  padding: 20px;
  margin: 20px auto;
}

.chart_container:last-child {
  margin-bottom: 70px;
}

.aura_animation {
  width: 50%;
  margin-top: 20px;
}

.top_value {
  font-family: "Vulf Sans Demo";
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  /* color: var(--font_color); */
  color:white;
  display: flex;
}

.top_label {
  font-family: "Vulf Mono Demo";
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: -2%;
  color: rgba(173, 173, 173, 1);
  display: flex;
}

.top_element {
  border: 2px solid;
  border-radius: 10px;
}

.m-10 {
  margin: 10px;
}

/* .card-container {
  display: flex;
  margin-top: 12%;
  margin-left: 15%;
  margin-right: 15%;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
}

.card {
  width: 275px;
  height: 350px;
  border: 4px solid black;
  margin-top: 50px;
  border-radius: 2px;
  -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.75);
  transform-style: preserve-3d;
  transition: transform 1s;
}


.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.card-back {
  background: wheat;
}

.card-text {
  list-style: none;
  margin: 20px 50px 0px 50px;
  line-height: 40px;
}

.card-text li:not(:last-child) {
  border-bottom: 1px solid #0004;
}

.card-img {
  height: 150px;
  width: 268px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 68%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 68%, 0 100%);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.card.flipped {
  transform: rotateY(180deg);
}
.card-front:nth-child(1) {
  animation: fadeIn 1.5s 0.3s backwards;
}

.card-front:nth-child(2) {
  animation: fadeIn 1.5s 0.6s backwards;
}
.card-front:nth-child(3) {
  animation: fadeIn 1.5s 0.9s backwards;
}
.card-front:nth-child(4) {
  animation: fadeIn 2s 1.2s backwards;
}
.card-front:nth-child(5) {
  animation: fadeIn 2s 1.5s backwards;
}
.card-front:nth-child(6) {
  animation: fadeIn 2s 1.8s backwards;
} */
