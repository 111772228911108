.orders1 {
  display: block;
  padding: 28px;
  min-width: 380px;
  overflow-y: scroll;
  background-color: var(--background-color);
  margin-left: calc(18% + var(--filter_width));
  text-align: left;
}

.height90 {
  height: 90vh;
}

.orders1::-webkit-scrollbar {
  display: none;
}

.ant-radio-wrapper {
  color: var(--font_color) !important;
}

.orders2 {
  display: block;
  width: calc(52% - var(--filter_width));
  padding: 28px;
  border: 1px var(--border_color) solid;
  height: 78vh;
  border-radius: 12px;
  background-color: var(--background-color);
  margin: 28px 28px 28px 0;
  overflow-y: scroll;
  text-align: left;
}

.orders2::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Recoleta-SemiBold";
  src: url("/public/fonts/Recoleta-SemiBold.ttf");
}

@font-face {
  font-family: "Recoleta";
  src: url("/public/fonts/Recoleta-Bold.ttf");
}

@font-face {
  font-family: "Vulf Sans Demo";
  src: url("/public/fonts/VulfSansDemo-Regular.otf");
}

@font-face {
  font-family: "Vulf Mono Demo";
  src: url("/public/fonts/vulfmonodemo-regular.otf");
}

.recoleta {
  font-family: "Recoleta" !important;
}

.recoleta-semibold {
  font-family: "Recoleta-SemiBold" !important;
}

.vulfsans {
  font-family: "Vulf Sans Demo" !important;
}

.vulfmono {
  font-family: "Vulf Mono Demo" !important;
}

#map {
  height: 346px;
  width: 100%;
  border-radius: 20px;
}

.delivery {
  margin: 18px 0 24px 0;
  display: flex;
}

.order_view {
  margin: 24px 0 74px 0;
  text-align: left;
}

.orders {
  display: flex;
  background-color: var(--background-color);
}

.mobile_order2 {
  border-radius: 12px;
  border: 1px var(--border_color) solid;
  margin: 20px 0;
  padding: 24px;
}

.order .desktop_version {
  display: block;
}

.order .tablet_version {
  display: none;
}

.order .mobile_version {
  display: none;
}

.review_button {
  line-height: 14px;
  background: transparent;
  border-radius: 100px;
  color: var(--font_color);
  border: 1px solid var(--font_color);
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  width: min-content;
  padding: 5px 10px;
  margin-left: 10px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.order_empty_container {
  margin-left: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85%;
  padding-bottom: 30px;
}

.order_empty_image {
  width: 270px;
  height: fit-content;
  margin: 20px;
}

.order_empty_font{
  max-width: 350px;
  padding: 20px;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
  .order .desktop_version {
    display: none;
  }

  .order_empty_container {
    margin-left: 300px;
  }

  .order_empty_image {
    max-width: 40%;
    min-width: 270px;
  }

  .order .tablet_version {
    display: block;
  }

  .order .mobile_version {
    display: none;
  }

  .orders1 {
    width: 75%;
  }

  .orders2 {
    margin-left: calc(18% + var(--filter_width) + 28px);
    width: calc(82% - var(--filter_width)-28px);
  }
}

@media screen and (max-width: 680px) {
  .order_view {
    margin: 24px 0;
  }

  .order_empty_image {
    max-width: 80%;
    min-width: 270px;
    height: fit-content;
  }

  .order_empty_container {
    margin-left: 0%;
    height: 70%;
  }

  .order .desktop_version {
    display: none;
  }

  .order .tablet_version {
    display: none;
  }

  .order .mobile_version {
    display: block;
  }

  .contact-us {
    margin-bottom: 70px;
  }
}


.page_404_container {
  background: var(--background-color);
  justify-content: center;
  align-items: center;
}

.page404_img {
  padding: 30px;
  width: 200px;
}

.page404_img_container {
  align-items: center;
  justify-content: center;
  display: flex;
}