.spotifyPl {
  position: relative;
  display: flex;
  top: 3px;
}

.spotifyPl__button {
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;
  text-align: left;
  outline: none;
}

.spotifyPl__button:focus {
  outline: none;
}

.spotifyPl__logo-container {
  width: 54px;
  position: relative;
}

.spotifyPl__notes {
  position: relative;
  left: -35px;
  bottom: -30px;
  width: 150px;
}

.spotifyPl__rings {
  width: 56px;
}

.spotifyPl__logo {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 32px;
}

.spotifyPl__devices {
  width: 80px;
  position: absolute;
  bottom: 70px;
  left: -12px;
  border-radius: 10px;
  border: 1px solid #000;
  padding: 5px 5px 8px;
  background-color: rgba(255,255,255,0.9);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #000;
}

.spotifyPl__devices--header {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.spotifyPl__devices--option {
  border-top: 1px solid #000;
  cursor: pointer;
  font-size: 11px;
  line-height: 13px;
  padding-top: 3px;
  margin-top: 3px;
}

.desktop_mode{
  display: flex;
}
.mobile_mode{
  display: none;
}

@media screen and (max-width: 680px) {
  .desktop_mode{
    display: none;
  }
  .mobile_mode{
    display: flex;
  }
  .spotifyPl__notes {
    position: fixed;
    right: 40px;
    left: unset;
    bottom: -20px;
    width: 150px;
  }
  .spotifyPl__devices {
    width: 130px;
    top: 70px;
    right: 10px;
    bottom: unset;
    left: unset;
  }
}