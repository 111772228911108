.personal_info1 {
    display: block;
    width: 30%;
    padding: 0 48px 0 48px;
    margin-top: 50px;
    height: 91%;
    background-color: var(--background-color);
    margin-left: calc(18% + var(--filter_width));
    text-align: left;
}

.ant-radio-wrapper {
    color: var(--font_color) !important;
    width: 50%;
    justify-content: center;
}

.personal_info2 {
    display: block;
    width: 25%;
    padding: 43px;
    /* border-right: 1px var(--border_color) solid; */
    height: 91%;
    background-color: var(--background-color);
    /* margin-left: calc(18% + 100px); */
    text-align: left;
}

.info_title {
    margin-bottom: 28px;
}

.info_each {
    margin: 14px 0;
}

.ant-space-item {
    padding: 12px 0;
}

.drop_under {
    border-bottom: 1px solid var(--font_color2);
}

.personal_info1 .ant-input-affix-wrapper {
    padding: 0;
}

.ant-radio-wrapper {
    padding: 7px 0 !important;
    border: 1px solid var(--font_color2);
    margin-right: 0px !important;
}

.save_buttons {
    padding: 30px;
    background-color: var(--background-color);
    gap: 20px;
    display: flex;
    margin-left: calc(var(--filter_width) + 19%);
}

.save_button {
    background: #EE4744;
    border-radius: 12px;
    width: 150px;
    height: 56px;
    border: none;
}

.cancel_button {
    background: transparent;
    border: none;
    width: 100px;
    height: 56px;
}

.personal_info {
    display: flex;
    background-color: var(--background-color);
    padding-bottom: 48px;
    overflow-y: scroll;
}

.personal_info .ant-select-arrow {
    display: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: rgba(51, 51, 51, 0.5) !important;
    color: var(--font_color) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none !important;
}

.ant-radio-inner::after {
    background-color: #EB4949 !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #EB4949 !important;
}

.profile_phone.phone_label {
    font-family: 'Vulf Mono Demo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    color: #ADADAD;
}

@media screen and (min-width: 1200px) {
    .personal_info {
        height: calc(100vh - var(--filter_width) - 40px);
    }
}

@media screen and (min-width: 681px) {
    .loader_container {
        height: 100%;
        display: flex;
        justify-content: center;
        margin-left: 15%;
    }
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
    .personal_info {
        display: block;
    }

    .personal_info2 {
        margin-left: calc(18% + var(--filter_width));
        width: 50%;
    }

    .personal_info1 {
        border: none;
        width: 50%;
    }

    .save_buttons {
        justify-content: space-between;
    }
}

@media screen and (max-width: 680px) {

    .personal_info1 {
        margin-left: 0;
        width: 100%;
        height: 100%;
        padding: 30px 30px 0px 30px;
        margin-top: 0;
        overflow: auto;
    }

    .personal_info2 {
        /* margin-left:0; */
        width: 100%;
        padding: 30px;
        /* padding-bottom: 167px; */
    }

    .personal_info2:last-child {
        /* margin-left:0; */
        width: 100%;
        padding: 0px 30px;
        padding-bottom: 167px;
    }

    .personal_info {
        display: block;
        margin-top: 20px;
    }

    .save_buttons {
        margin: 0;
        justify-content: space-between;
        position: fixed;
        padding-bottom: 35px;
        width: 100%;
        background: var(--background-color);
    }
}