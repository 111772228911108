.graphic_card {
  border: 1px solid var(--border_color);
  padding: 1px;
  border-radius: 12px;
  aspect-ratio: 16 / 9; /* Set a 16:9 aspect ratio */
  width: 100%;
  //min-height: 250px;
}

.size_button {
  background: transparent !important;
  padding: 9px 7px !important;
  border-radius: 100px !important;
  min-width: 50px !important;
  border: none !important;
  cursor: pointer !important;
  color: var(--font_color) !important;
  height: 100% !important;
}

.size_button:hover {
  background: #eb4949 !important;
}

.size_button:active {
  background: #eb4949 !important;
}

.size_button:focus {
  background: #eb4949 !important;
}

.size_part {
  border: 1px var(--border_color) solid;
  border-radius: 100px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ant-drawer-content-wrapper {
  width: 450px !important;
}

.graphic_card .ant-card-body {
  padding: 0;
  background-color: var(--background-color);
  color: var(--font_color);
  border-radius: 0 0 12px 12px;
}

.graphic_card .ant-card {
  background: none;
}

.home_card_content {
  display: block;
  padding: 6px 12px;
}

.ant-card-bordered {
  border: none !important;
}

@media screen and (max-width: 679px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    height: 100vh !important;
  }

  .add_bag_btn {
    width: 100% !important;
    padding: 14px;
  }
}

.color_button {
  border-radius: 100px;
  width: 30px;
  height: 30px;
  background-image: url("color.png");
  margin: 5px;
  cursor: pointer;
}

.location_part {
  border: 1px var(--border_color) solid;
  border-radius: 100px;
  width: fit-content;
  align-items: center;
  padding: 10px;
}

.tags_button {
  background-color: var(--background-color);
  border-radius: 7px;
  /* height: 30px; */
  cursor: pointer;
  margin: 3px;
  padding: 5px 10px;
  border: 1px var(--font_color) solid;
}

.ant-divider-horizontal {
  background: var(--border_color);
}

.ant-drawer-body {
  padding: 0 !important;
  background: var(--overlay-color);
}

.bag_animation_active {
  animation: bag_animation 1.7s ease-in-out forwards;
}

.opacity_bag {
  animation: opacityAnimation 0.5s ease-in-out forwards;
}

@keyframes opacityAnimation {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes bag_animation {
  0% {
    overflow: hidden;
  }

  30% {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    right: 22%;
    bottom: 3%;
  }

  50% {
    clip-path: circle();
    width: 40px;
    height: 40px;
    overflow: hidden;
    right: 22%;
    bottom: 3%;
    opacity: 1;
  }

  to {
    opacity: 0;
    right: 3%;
    bottom: 93%;
    clip-path: circle();
    width: 40px;
    height: 40px;
    overflow: hidden;
  }
}

.add_bag_btn {
  position: fixed;
  right: 0%;
  bottom: 0%;
  width: 450px;
  background: #eb4949;
  padding: 24px;
  z-index: 2;
}

.add_bag_btn.disabled {
  background: #333333;
}

.tray {
  min-height: calc(100vh - 88px);
  padding: 24px;
  background: var(--background-color);
}

.black {
  background-color: #000000;
}
.overlay {
  /* Styles for the overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  z-index: 4; /* Place the overlay above other elements */
}

.tray::-webkit-scrollbar {
  display: none;
}

.ant-drawer-body::-webkit-scrollbar {
  display: none;
}

.buy-now-button {
  background-color: #eb4949;
  margin: 0 8px 8px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.buy-now-button.disabled {
  background-color: rgba(51, 51, 51, 0.5);
}

.buy-now-button__label {
  flex: 0 0 auto;
  margin-left: 12px;
  font-family: "Vulf Sans Demo";
  font-weight: 400;
  font-size: 9px;
  color: #ffffff;
  opacity: 0.5;
}

.buy-now-button__text {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  margin: 0 16px;
  flex: 1 0 auto;
}

.buy-now-button__price {
  height: 20px;
  border-right: 1px solid rgba(13, 13, 13, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 680px) {
  .tray {
    border-radius: 20px;
    border: 1px solid var(--font_color2);
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
    height: 100vh !important;
  }

  .add_bag_btn {
    width: 100% !important;
    padding: 14px;
    padding-bottom: 25px;
  }

  .total_price {
    font-size: 16px;
  }

  .select-modal {
    width: 100% !important;
  }
}

.product__variant .ant-select-selector {
  border-bottom: 0 !important;
}

.product__variant .ant-select {
  background-color: #eb4949;
  border-radius: 999px;
  color: #ffffff;
  padding: 5px;
  margin-bottom: 10px;
}

.product__variant .select-button {
  position: relative;
  display: inline-block;
  background-color: #eb4949;
  border-radius: 999px;
  color: #ffffff;
  padding: 4px 36px 4px 16px;
  margin-bottom: 10px;
  height: 42px;
  line-height: 16px;
  cursor: pointer;
}

.product__variant .select-button__text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.product__variant .select-button__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 10px;
}

.product__variant .ant-select-selection-placeholder {
  color: #ffffff;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/***
 * Select modal
 */
.select-modal {
  z-index: 5;
  position: absolute;
  background-color: var(--background-color);
  right: 0;
  bottom: 0;
  width: 450px;
  border: 1px solid var(--font_color2);
  border-radius: 20px 20px 0px 0px;
  padding-bottom: 12px;
}

.select-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 21px;
}

.select-modal__divider {
  border-bottom: 1px solid var(--font_color2);
  margin-bottom: 12px;
}

.select-modal__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px;
  font-family: "Vulf Sans Demo";
  font-size: 14px;
  cursor: pointer;
}

.white_font {
  color: #ffffff;
}

.black_font {
  color: black;
}
