.passport {
  /* display: flex; */
  width: calc(82% - var(--filter_width));
  padding: 50px 48px;
  /* border-right: 1px --font_color2 solid; */
  height: 100%;
  background-color: var(--background-color);

  margin-left: calc(18% + var(--filter_width));
  text-align: left;
  align-items: center;
}

.pass_main {
  transition: transform 0.8s ease;
  transform-style: preserve-3d;

  border: 1px solid rgba(204, 183, 131, 0.7);
  /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%); */
  /* background-color: #0d0d0d; */
  border-radius: 44px;
  width: 100%;
  /* justify-content: center; */
  margin: auto;
  /* padding: 0 40px; */
  text-align: start;
  /* overflow: hidden; */
  /* gap: 50px; */
  height: 100%;
  background: var(--background-gradient-color);
}

.pass_item {
  display: grid;
  /* margin: 8px 0; */
  width: 35%;
}
.right_item {
  margin-left: 0px;
}
.pass_row {
  /* gap: 50px; */
  margin: 6px 30px;
  width: 100%;
}

.pass_title {
  font-family: "Vulf Mono Demo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #adadad;
  padding: 8px 0;
}

.pass_content {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: var(--font_color);
}

.pass_avatar {
  border-radius: 12px;
  border: 1px rgba(204, 183, 131, 0.7) solid;
  background: var(--background-color);
}

.pass_code {
  border-radius: 12px;
  border: 1px rgba(204, 183, 131, 0.7) solid;
  background: var(--background-color);

}

.pass_identify {
  /* position:absolute; */
  /* right: 15%; */
  /* top: 10%; */
  margin-top: -34px;
}

.barcode {
  /* position: absolute; */
  justify-content: end;
  display: flex;
  align-items: end;
  width: 60%;
  /* height: 30%; */
  float: right;
  margin-top: 60px;
}

.pass_earth {
  /* position: absolute; */
  bottom: 16%;
  right: 12%;
}

.pass_front {
  background-image: url(pass_earth.svg);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  height: 100%;
}

.pass_rotate {
  display: flex;
  gap: 20px;
  cursor: pointer;
  justify-content: center;
}

.pass_back {
  background-image: url(pass_earth.svg);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  /* transform: rotateY(180deg);
  transform: scaleX(-1); */
  height: 100%;
  justify-content: center;
  align-items: center;
}

.pass_info1 {
  /* display: grid; */
  margin-top:99px;
  align-content: space-around;
  padding: 15px 0;
  width: 68%;
}

.pass_info2 {
  width: 28%;
  /* padding-left: 80px; */
}

.pass_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.isActive {
  background-color: var(--background-color) !important;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
  .pass_content {
    font-size: 18px;
  }

  .pass_main {
    border-radius: 32px;
    width: 100%;
  }

  .pass_row {
    margin: 0px 15px;
  }

  .pass_logo {
    width: 50%;
  }

  .pass_front {
    background-size: 40%;
  }

  .barcode {
    margin-top: 30px;
  }

  .pass_back {
    background-size: 40%;
  }
}

@media screen and (max-width: 680px) {
  .passport {
    margin-left: 0;
    width: 100%;
    padding: 50px 28px;
  }

  .pass_main {
    width: 100%;
  }

  .pass_logo {
    width: 60%;
  }

  .pass_front {
    flex-direction: column;
    background-position: 100% 100%;
    background-size: 310px 176px;
    border-radius: 44px;
  }
  .pass_back {
    background-position: 100% 100%;
    background-size: 310px 176px;
    border-radius: 44px;
  }
  .pass_info1 {
    order: 2;
    width: 100%;
    margin-top:0px;
  }

  .pass_info2 {
    order: 1;
    display: inline-flex;
    width: 100%;
  }

  .pass_identify {
    margin-left: 20px;
  }

  .pass_item {
    margin: unset;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
  }

  .right_item {
    margin-left: 60px;
  }

  

  .barcode {
    margin-top: 0;
    margin-left:40px;
    padding: 30px;
    aspect-ratio: 1/1;
    align-items: start;
    width: 44%;
  }

  .pass_content {
    font-size: 16px;
    font-weight: 600;
  }

  .pass_row {
    width: auto;
    margin: 0 20px;
    justify-content: space-between;
  }

  .pass_rotate {
    display: none;
  }

  .pass_title {
    font-size: 14px;
  }
}

@media screen and (max-width: 520px) {
  .barcode {
    width: 48%;
  }

  .pass_content {
    font-size: 16px;
  }

  .pass_title {
    font-size: 14px;
  }

  .pass_info1 {
    /* //padding: 0 0 15px 0; */
    margin-top:0px;
  }
}

.card-container {
  display: flex;
  margin-top: 12%;
  margin-left: 15%;
  margin-right: 15%;
  justify-content: space-evenly;
  text-align: center;
  flex-wrap: wrap;
}

.card {
  /* width: 275px;
  height: 350px;
  border: 4px solid black; */
  /* margin-top: 50px;
  border-radius: 2px; */
  /* -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.75); */
  /* box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.75); */
  background-color: inherit;
  transform-style: preserve-3d;
  transition: transform 1s;
  border: none;
}

.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.card-back {
  /* background: wheat; */
}

.card-text {
  list-style: none;
  margin: 20px 50px 0px 50px;
  line-height: 40px;
}

.card-text li:not(:last-child) {
  border-bottom: 1px solid #0004;
}

.card-img {
  height: 150px;
  width: 268px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 68%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 68%, 0 100%);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.card.flipped {
  transform: rotateY(180deg);
}
.card-front:nth-child(1) {
  animation: fadeIn 1.5s 0.3s backwards;
}

.card-front:nth-child(2) {
  animation: fadeIn 1.5s 0.6s backwards;
}
.card-front:nth-child(3) {
  animation: fadeIn 1.5s 0.9s backwards;
}
.card-front:nth-child(4) {
  animation: fadeIn 2s 1.2s backwards;
}
.card-front:nth-child(5) {
  animation: fadeIn 2s 1.5s backwards;
}
.card-front:nth-child(6) {
  animation: fadeIn 2s 1.8s backwards;
}
