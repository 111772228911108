.edit_personal_info {
    display: flex;
    background-color: var(--background-color);
    overflow: auto;
    top: 0;
}

.edit_personal_info1 {
    display: block;
    width: 30%;
    padding: 0 48px 0 48px;
    height: 91%;
    background-color: var(--background-color);
    /* margin-left: calc(18% + var(--filter_width)); */
    text-align: left;
}

.ant-radio-wrapper {
    color: var(--font_color) !important;
    width: 50%;
    justify-content: center;
}

.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
    color: var(--font_color) !important;
}

.ant-input-textarea-show-count::after {
    color: var(--font_color) !important;
}


.info_title {
    margin-bottom: 28px;
}

.info_each {
    margin: 14px 0;
}

.ant-space-item {
    padding: 12px 0;
}

.drop_under {
    border-bottom: 1px solid var(--font_color2);
}

.edit_personal_info1 .ant-input-affix-wrapper {
    padding: 0;
}

.ant-radio-wrapper {
    padding: 7px 0 !important;
    border: 1px solid var(--font_color2);
    margin-right: 0px !important;
}

.save_buttons {
    padding: 30px;
    background-color: var(--background-color);
    gap: 20px;
    display: flex;
    margin-left: calc(var(--filter_width) + 19%);
}

.save_button {
    cursor: pointer;
    background: #EB4949;
    border-radius: 12px;
    width: 150px;
    height: 56px;
    border: none;
}

.save_button:disabled {
    background: #666 !important;
    border-radius: 12px;
    width: 150px;
    height: 56px;
    border: none;
}

.cancel_button {
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100px;
    height: 56px;
}

.edit_personal_info .ant-select-arrow {
    display: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: rgba(51, 51, 51, 0.5) !important;
    color: var(--font_color) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none !important;
}

.ant-radio-inner::after {
    background-color: #EB4949 !important;
}

.ant-radio-checked .ant-radio-inner {
    border_color: #EB4949 !important;
}

.profile_phone.phone_label {
    font-family: 'Vulf Mono Demo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    color: #ADADAD;
}

@media screen and (min-width: 681px) {
    .edit_personal_info {
        display: block;
        height: 100%;
    }

    .edit_personal_info1 {
        border: none;
        width: 100%;
    }

    .save_buttons {
        justify-content: space-between;
    }
}

@media screen and (max-width: 680px) {

    .edit_personal_info {
        border-radius: 16px;
        display: block;
        width: 98%;
        height: 99%;
        margin: 5px;
        padding-bottom: 20px;
        border-width: 1px;
        border: 1px var(--border_color) solid;
    }

    .edit_personal_info1 {
        margin-top: -20px;
        margin-left: 0;
        width: 100%;
        height: 80px;
        padding: 0 30px;
    }

    .save_buttons {
        margin: 0;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: var(--background-color);
    }
}

.space-info .ant-input {
    border-bottom-width: 0 !important;
}

.space-info {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #2e2e2e;
}

.space-color {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #2e2e2e;
    cursor: pointer;
}

.space-color .ant-input {
    border-bottom-width: 0 !important;
}

.save_buttons1 {
    margin: 0;
    justify-content: space-between;
    bottom: 0;
    padding-bottom: 25px;
    width: 100%;
    background: var(--background-color);
    display: flex;
}

.edit_menu_close {
    z-index: 3;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.edit_menu_close:hover {
    opacity: 0.8;
    transform: scale(1.2);
    transform: rotate(20deg);
}