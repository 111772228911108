.banner {
    display: flex;
    align-items: center;
    overflow: hidden;
    /* justify-content: flex-end; */
    border-radius: 12px;
    border: 1px var(--border_color) solid;
    width: 100%;
    height: 120px;
    background-color: transparent;
    justify-content: space-between;
    margin: 25px 0;
}

.innerDIV {
    display: flex;
    width: 7%;
    height: 100%;
    border-bottom-left-radius: 50px;
    transform: rotate(51deg);
    border-left: 1px solid var(--font_color2);
    border-bottom: 1px solid var(--font_color2);
    margin-left: 10%;

}

.video-player {
    width: 40%;
    border-top-left-radius: 100%;
}


.video_player video {
    height: 100%;
    object-fit: cover;
    /* position: fixed; */
    width: 302%;
    z-index: 99999;
    border-radius: 0 12px 12px 0;
    /* border-top-left-radius: 31%; */
}

.video_player video,
video:before {
    -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
}

.video_player {
    width: 33%;
    height: 100%;
    display: flex;
    z-index: 0;
}

.banner_text {
    display: grid;
    text-align: left;
}

.become_text a {
    text-decoration: underline;
    color: var(--font_color6);
    font-size: 12px;
}

.banner_app {
    margin-right: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.banner_content {
    display: flex;
    justify-content: center;
    width: 50%;
}

.star_front {
    margin-left: 50px;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
    .drop_text {
        font-size: 20px !important;
        line-height: 20px !important;
    }

    .banner {
        height: 80px;
    }

    .banner_text {
        margin-right: 0;
    }

    .banner_app {
        margin-right: 0;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 680px) {
    .video_player {
        display: none !important;
        visibility: hidden;
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;
        overflow: hidden;
        z-index: -100;
    }

    .video_player video {
        display: none !important;
        visibility: hidden;
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;
        overflow: hidden;
        z-index: -100;
    }

    .banner_text {
        display: flex;
        margin-right: 0;
        gap: 10px;
    }

    .banner {
        justify-content: normal;
    }

    .banner_content {
        display: block;
        width: 100%;
    }

    .drop_text {
        font-size: 18px;
    }

    .become_text a {
        font-size: 12px;
    }

    .star_front {
        margin-left: 0;
        height: 100%;
    }

    .mid_content {
        flex: 1;
        justify-content: center;
    }
}