.page_404_container {
    background: var(--background-color);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page_404_order_font {
    font-family: 'Recoleta';
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    padding: 50px;
}

.page_404_order_divider {
    background-color: #555555;
    width: 100%;
    height: 2px;
    margin-top: 30px;
}

.page_404_order_dropButton {
    cursor: pointer;
    position: relative;
    bottom: 40px;
    left: -20%;
}

.page_404_order_dropfont {
    position: relative;
    margin-top: -80px;
    color: white;
}

.page404_img {
    padding: 30px;
    width: 80%;
}

.page404_img_container {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
}

.flex-1 {
    flex: 1;
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
    .page_404_container {
        flex-direction: column-reverse;
    }

    .page404_img {
        width: 680px;
    }

    .page404_tablet_mode {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page_404_order_dropButton {
        cursor: pointer;
        position: relative;
        bottom: 40px;
        left: -20%;
    }
}

@media screen and (max-width: 680px) {

    .page404_img {
        width: 93%;
        padding-bottom: 0px;
    }

    .page_404_container {
        flex-direction: column-reverse;
    }

    .page404_img_container {
        flex: none !important;
    }

    .page404_tablet_mode {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20%;
    }

    .page_404_order_dropButton {
        position: relative;
        bottom: 40px;
        left: -20%;
    }

    .page_404_order_font {
        font-family: 'Recoleta';
        font-weight: 700;
        font-size: 26px;
        line-height: 40px;
        padding: 50px;
    }
}