.notification_body {
    padding: 32px;
    border-bottom: 1px var(--border_color) solid;
}

.sms_input {
    --ReactInputVerificationCode-itemWidth: 3.5rem !important;
}

.noti_header {
    padding: 25px 32px 32px 32px;
    border-bottom: 1px var(--border_color) solid;
    position: relative;
}

.close {
    position: absolute;
    right: -20px;
    top: 32px;
}

.noti_footer {
    margin: auto;
    background: #EB4949;
    padding: 16px;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
    color: white;
}

.skip_button {
    cursor: pointer;
    color: #26546C;
}

.noti_part {
    border-width: 0 !important;
    max-width: 500px;
}

.modal-backdrop.show {
    opacity: 0.7;
}

.product_img {
    width: 70%;
    display: flex;
}

.map_img {
    width: 40%;
    display: flex;
}

.image_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.close_button {
    position: absolute;
    right: 10px !important;
    top: 10px !important;
}

@media screen and (max-width: 680px) {
    .sms_input {
        --ReactInputVerificationCode-itemWidth: 2rem !important;
    }

    .product_img {
        width: 80%;
        display: flex;
    }

    .map_img {
        width: 60%;
        display: flex;
    }
}

@media screen and (min-width: 681px) and (max-width: 1200px) {}