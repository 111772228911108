.channels {
    position: fixed;
    width: var(--filter_width);
    padding: 20px 12px;
    border-right: 1px var(--border_color) solid;
    height: calc(100vh - 80px);
    background-color: var(--background-color);
}

.channel_item {
    display: flex;
    align-items: center;
    gap: 7px;
    margin: 13px 0;
}

.channel_spotify {
    padding-left: 10px;
}

.filter .channel_spotify {
    bottom: 10px;
    /* position: fixed; */
    display: flex;
    gap: 50px;
}

.filter-content {
    width: 100%;
    height: 100%;
}

.filter {
    position: fixed;
    text-align: left;
    overflow: scroll;
    width: var(--filter_width);
    padding: 20px 12px;
    border-right: 1px var(--border_color) solid;
    height: 91%;
    background-color: var(--background-color);
    z-index: 2;
}

.filter::-webkit-scrollbar {
    display: none;
}

.filter-content::-webkit-scrollbar {
    display: none;
}

.ant-collapse {
    background-color: var(--background-color) !important;
    color: var(--font_color) !important;
    border: none !important;
}

.ant-collapse-content {
    background-color: var(--background-color) !important;
    color: var(--font_color) !important;
}

.ant-collapse-header {
    color: var(--font_color) !important;
}

.ant-collapse-item {
    border: none !important;
}

.filter .channel_item {
    display: flex;
    align-items: center;
    gap: 7px;
    margin: 10px 0;
    padding: 0 20px;
}

.ant-collapse-header-text {
    font-family: 'Vulf Sans Demo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.ant-tabs-ink-bar {
    background: #EB4949 !important;
    height: 3px !important;
}

.channel_viewer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.grid_view {
    cursor: pointer;
    color: var(--font_color6);
    display: flex;
}

.full_view {
    cursor: pointer;
    color: var(--font_color6);
    display: flex;
}

.full_view:focus {
    color: var(--font_color);
}

.grid_view:focus {
    color: var(--font_color);
}

.nav__profile-img {
    border-radius: 30px;
    height: 26.25px;
    width: 26.25px;
    border: 1px solid #CCB783;
    margin-right: 5px;
}

.nav__img {
    height: 26.25px;
    width: 26.25px;
    margin-right: 5px;
}

@media screen and (max-width: 680px) {
    .filter-content {
        overflow-y: scroll;
    }

    .channels {
        display: none !important;
    }

    .filter {
        width: 220px;
        height: 100%;
        overflow: hidden;
    }

    .menu_close {
        z-index: 3;
        position: absolute;
        left: 200px;
        top: 20px;

    }

    .filter .channel_spotify {
        display: flex;
        justify-content: flex-end;
        width: 200px;
    }
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
    .channel_text {
        display: none !important;
    }

    .channels .channel_item {
        justify-content: center;
        /* margin: 25px 0; */
    }

    .filter .channel_item {
        padding: 0;
    }

    .ant-collapse-content>.ant-collapse-content-box {
        padding: 5px !important;
    }

    .channel_image {
        width: 16px;
    }
}