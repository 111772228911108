/*
 * Copy contents directly
@import url('//db.onlinewebfonts.com/c/de588c21911c23ad80de20d8d17c0f7a?family=IFWSLA+VulfMono-Black');
*/
@font-face {
    font-family: "IFWSLA VulfMono-Black";
    src: url("//db.onlinewebfonts.com/t/de588c21911c23ad80de20d8d17c0f7a.eot");
    /* IE9*/
    src: url("//db.onlinewebfonts.com/t/de588c21911c23ad80de20d8d17c0f7a.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("//db.onlinewebfonts.com/t/de588c21911c23ad80de20d8d17c0f7a.woff2") format("woff2"),
        /* chrome firefox */
        url("//db.onlinewebfonts.com/t/de588c21911c23ad80de20d8d17c0f7a.woff") format("woff"),
        /* chrome firefox */
        url("//db.onlinewebfonts.com/t/de588c21911c23ad80de20d8d17c0f7a.ttf") format("truetype"),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
        url("//db.onlinewebfonts.com/t/de588c21911c23ad80de20d8d17c0f7a.svg#IFWSLA VulfMono-Black") format("svg");
    /* iOS 4.1- */
}

.modal-content {
    background: transparent;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: inherit;
    border: none;
}

.modal-body {
    width: 100%;
    background-color: var(--background-color) !important;
    color: var(--font_color) !important;
    border: 1px var(--border_color) solid;
    border-radius: 12px;
    padding: 0;
    height: fit-content;
}

.checkout_header {
    border-bottom: 1px var(--border_color) solid;
    height: 60px;
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.checkout_body {
    padding: 16px;
    max-height: 360px;
    overflow: scroll;
}
.checkout_body::-webkit-scrollbar {
    display: none;
}

.bag-form-input {
    font-family: 'Vulf Sans Demo';
    font-style: normal;
    /* font-size: 14px; */
}

.checkout_footer {
    margin: auto;
    background: #565656;
    padding: 16px;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
    color: white;
    border-width: 0;
    width: 100%;
}

.checkout_footer:focus {
    outline: 0;
}

label {
    margin-bottom: 0;
}

.order_color {
    border-radius: 100px;
    height: 15px;
    width: 15px;
    background: red;
}

.order_footer {
    border-top: 1px var(--border_color) solid;
}

.order_footer_middle {
    border-top: 1px var(--border_color) solid;
    border-bottom: 1px var(--border_color) solid;
}

.ant-tabs {
    color: var(--font_color) !important;
}

.ant-space-align-center {
    align-items: center;
    justify-content: space-between;
    display: flex !important;
}

.success_footer {
    margin: auto;
    background: #EB4949;
    padding: 16px;
    border-radius: 0 0 12px 12px;
    cursor: pointer;
}

.checkout_body .ant-select-arrow {
    display: none;
}

.ant-select-selection-item {
    color: var(--font_color);
}

.ant-tabs-top>.ant-tabs-nav::before {
    border: none !important;
}

.ant-tabs-nav-list {
    border-bottom: 1px solid var(--font_color2);
}

.ant-tabs-tab {
    color: var(--font_color2);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--font_color) !important;
    /* color: #1890ff; */
}

.ant-tabs-tab-active label {
    color: var(--font_color) !important;
}

.ant-radio-button-wrapper {
    width: 50%;
}

.ant-tabs-tab:hover {
    color: var(--font_color) !important;
}

.ant-tabs-tab:active {
    color: var(--font_color) !important;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
    color: #EB4949 !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    border-bottom: 1px solid var(--font_color2);
}

#tax {
    border: 1px solid var(--font_color2);
    width: 200px;
    text-align: left;
}

.product_row {
    gap: 20px;
    margin-bottom: 26px;
}

@media screen and (max-width: 680px) {
    .product_row {
        gap: 4px;
    }

    .modal-content {
        flex-wrap: wrap-reverse;
    }

    .login_part {
        order: 2;
    }
}

@media screen and (min-width: 681px) and (max-width: 1200px) {
    .login_part {
        /* width: max-content; */
        max-width: 100%;
    }

    .phone {
        width: 100%;
    }

    .modal-dialog {
        max-width: 700px;
    }
}

.cart-product {
    display: flex;
    gap: 10px;
}

.cart-product__close {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
}

.cart-product__row {
    gap: 10px;
    display: flex;
}

.cart-product__row .font14_w4 {
    font-size: 12px;
}

.cart-product__row>div {
    margin-top: 8px;
}

.cart-product__row>div {
    flex: 1;
}

.cart-product__row>div:last-child {
    flex: 1;
    text-align: right;
}

.cart-product__summary {
    flex: 1 0 0;
}

.cart-product__thumbnail {
    flex: none;
}

.cart-product__image {
    width: 72px;
    height: 84px;
    background-size: cover;
    background-repeat: none;
    background-position: center;
}

.cart-product__badge {
    display: flex;
    align-items: center;
}

.cart-product__badge-space {
    padding-right: 4px;
    flex: auto;
}

.cart-product__badge-button {
    flex: initial;
}

.cart-product__badge-info {
    padding: 4px;
    flex: none;
}

.cart-product__badge .cardbtn {
    padding: 0 4px;
    gap: 2px;
}

.cart-product__quantity {
    display: flex;
    gap: 5px;
}

.cart-product__price {
    flex: 0 0 auto;
    border-left: 1px solid var(--font_color2);
    width: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-product__price>span {
    margin-right: -12px;
}

.cart-product__details-text {
    margin: 8px 0;
}

.cart-product__details {
    padding-top: 8px;
    border-top: 1px solid var(--font_color2);
    margin-bottom: 30px;
}

.cart-product__size {
    align-items: center;
}

.cart-product__color {
    display: flex;
    align-items: center;
}

.cart-product__color .order_color {
    margin-left: 4px;
}

.payment-choice-divider {
    margin: 20px 0;
    position: relative;
    text-align: center;
}

.payment-choice-divider div {
    border-bottom: 1px var(--border_color) solid;
    width: calc(100% - 40px);
    position: absolute;
    top: 50%;
    left: 20px;
}

.payment-choice-divider span {
    position: relative;
    background-color: var(--background-color);
    padding: 0 20px;
}

.payment-request-button.StripeElement {
    border-bottom: 0;
}

.cvc_modal {
    background-color: var(--background-color);
    border-radius: 16px;
    position: absolute;
    bottom: 0;
    /* height: 250px; */
    width: 100%;
    border: 1px solid var(--font_color2);
    transition: bottom 0.5s ease-in-out;
}

.cvc_modal_hide {
    bottom: -100%;
    display: none;
}

.cvc_modal_show {
    bottom: 0;
}

.divider {
    border-bottom: 1px solid var(--font_color2);
    width: 100%;
}